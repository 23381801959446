
import { Options, Vue } from 'vue-class-component';
import { ElButton, ElInput } from 'element-plus';
import SearchHighlight from './searchHighlight.vue';

@Options({
    components: {
        SearchHighlight,
        ElButton,
        ElInput,
    },
})
export default class SearchQuestion extends Vue {
    private currentIdx = 0;
    private matchCount = 0;
    private keyword = '';
    private content = `
            我是一个问题<b>请回答</b>大赛时间</br>
      大赛流程<b>月</b>大赛举办方<b>大赛评委</b>怎么选！大赛共计5个赛道，每个赛道设置：

1个冠军队伍，奖金5万人民币，颁发获奖证书

1个亚军队伍，奖金3万人民币，颁发获奖证书

1个季军队伍，奖金2万人民币，颁发获奖证书

此外获奖队伍可获得腾讯集团校招/实习直面绿色通道`;
    mounted(): void {
        this.initWatch();
        const search = this.$refs.search;
        console.warn('search', search);
    }
    private initWatch() {
        this.$watch('keyword', (value: string) => {
            console.log('watchString', this.keyword);
        });
    }

    private searchNext() {
        (<SearchHighlight>this.$refs.search).searchNext();
    }
    private searchLast() {
        (<SearchHighlight>this.$refs.search).searchLast();
    }
    private matchCountChange(count: number) {
        this.matchCount = count;
    }
    private currentChange(idx: number) {
        this.currentIdx = idx;
    }
    private checkKeydown(event: KeyboardEvent) {
        if (event.shiftKey) {
            this.searchLast();
        } else {
            this.searchNext();
        }
    }
}
