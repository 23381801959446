
import { Options, Vue } from 'vue-class-component';

interface ITextInfoList {
    text: string;
    startIdx: number;
    endIdx: number;
}
interface IObject<T> {
    [key: string]: T;
}
interface ChildNode extends Node {
    after(...nodes: (Node | string)[]): void;
    before(...nodes: (Node | string)[]): void;
    remove(): void;
    replaceWith(...nodes: (Node | string)[]): void;
}
const PLUGIN_FLAG = 'search_hightlight_question';
@Options({
    props: {
        content: {
            type: String,
            default: '',
        },
        keyword: {
            type: String,
            default: '',
        },
        highlightStyle: {
            type: String,
            default: 'background: #ffff00',
        },
        currentStyle: {
            type: String,
            default: 'background: #ff9632',
        },
        regExp: {
            type: Boolean,
            default: false,
        },
    },
})
export default class SearchHighlight extends Vue {
    content!: string;

    keyword!: string;

    highlightStyle!: string;

    currentStyle!: string;

    regExp!: string;

    private contentShow = '';

    private lightIndex = 0;

    private matchCount = 0;

    private random: string = `${Math.random()}`.slice(2);

    mounted(): void {
        this.initWatch();
    }

    private initWatch() {
        this.$watch(
            'watchString',
            (value: string) => {
                this.replaceKeywords();
            },
            { immediate: true }
        );
        this.$watch(
            'watchStyle',
            (value: string) => {
                this.setStyle();
            },
            { immediate: true }
        );
        this.$watch(
            'lightIndex',
            () => {
                this.$emit('current-change', this.lightIndex);
            },
            { immediate: true }
        );
        this.$watch(
            'matchCount',
            () => {
                this.$emit('match-count-change', this.matchCount);
            },
            { immediate: true }
        );
    }

    beforeDestroy(): void {
        this.clearStyle();
    }

    get watchString(): Array<string> {
        return [this.content, this.keyword];
    }

    get watchStyle(): Array<number | string> {
        return [this.lightIndex, this.highlightStyle, this.currentStyle];
    }

    get flag(): string {
        return `${PLUGIN_FLAG}${this.random}`;
    }

    get styleSelector(): string {
        return `style[${this.flag}]`;
    }

    private getTextNodeList(dom: HTMLDivElement): Array<ChildNode | Text> {
        const nodeList = [...dom.childNodes];
        const textNodes = [];
        while (nodeList.length) {
            const node = nodeList.shift();
            if (node) {
                if (node.nodeType === node.TEXT_NODE && (<Text>(<unknown>node)).wholeText) {
                    textNodes.push(node);
                } else {
                    nodeList.unshift(...node.childNodes);
                }
            }
        }
        return textNodes;
    }

    private getTextInfoList(textNodes: Array<Text | ChildNode>): Array<ITextInfoList> {
        let length = 0;
        const textList = textNodes.map(node => {
            const startIdx = length;
            const endIdx = length + (<Text>node).wholeText.length;
            length = endIdx;
            return {
                text: (<Text>node).wholeText,
                startIdx,
                endIdx,
            };
        });
        return textList;
    }

    private getMatchList(content: string, keyword: string): Array<RegExpExecArray> {
        let keywords = keyword;
        if (!this.regExp) {
            const characters: IObject<boolean> = [...'\\[](){}?.+*^$:|'].reduce((r: IObject<boolean>, c: string) => {
                r[c] = true;
                return r;
            }, {});
            keywords = keyword
                .split('')
                .map(s => (characters[s] ? `\\${s}` : s))
                .join('[\\s\\n]*');
        }
        const reg = new RegExp(keywords, 'gmi');
        const matchList: Array<RegExpExecArray> = [];
        let match = reg.exec(content);
        while (match) {
            matchList.push(match);
            match = reg.exec(content);
        }
        return matchList;
    }

    private replaceMatchResult(textNodes: Array<Text | ChildNode>, textList: Array<ITextInfoList>, matchList: Array<RegExpExecArray>) {
        // 对于每一个匹配结果，可能分散在多个标签中，找出这些标签，截取匹配片段并用font标签替换出
        for (let i = matchList.length - 1; i >= 0; i--) {
            const match: RegExpExecArray = matchList[i];
            const matchStart = match.index;
            const matchEnd = matchStart + match[0].length; // 匹配结果在拼接字符串中的起止索引
            // 遍历文本信息列表，查找匹配的文本节点
            for (let textIdx = 0; textIdx < textList.length; textIdx++) {
                const { text, startIdx, endIdx } = textList[textIdx]; // 文本内容、文本在拼接串中开始、结束索引
                if (endIdx < matchStart) continue; // 匹配的文本节点还在后面
                if (startIdx >= matchEnd) break; // 匹配文本节点已经处理完了
                let textNode = textNodes[textIdx]; // 这个节点中的部分或全部内容匹配到了关键词，将匹配部分截取出来进行替换
                const nodeMatchStartIdx = Math.max(0, matchStart - startIdx); // 匹配内容在文本节点内容中的开始索引
                const nodeMatchLength = Math.min(endIdx, matchEnd) - startIdx - nodeMatchStartIdx; // 文本节点内容匹配关键词的长度
                if (nodeMatchStartIdx > 0) textNode = (<Text>textNode).splitText(nodeMatchStartIdx); // textNode取后半部分
                if (nodeMatchLength < (<Text>textNode).wholeText.length) (<Text>textNode).splitText(nodeMatchLength);
                const font = document.createElement('font');
                font.setAttribute(this.flag, `${i + 1}`);
                font.innerText = text.substr(nodeMatchStartIdx, nodeMatchLength);
                (<Node>textNode.parentNode).replaceChild(font, textNode);
            }
        }
    }

    private replaceKeywords() {
        let errFlag = false;
        if (this.regExp) {
            try {
                const reg = new RegExp(this.keyword);
                if (reg.test('')) errFlag = true;
            } catch (err) {
                errFlag = true;
            }
        }
        if (errFlag || !this.keyword) {
            this.contentShow = this.content;
            return;
        }
        const div = document.createElement('div');
        div.innerHTML = this.content;
        const textNodes = this.getTextNodeList(div);
        const textList = this.getTextInfoList(textNodes);
        const content = textList.map(({ text }) => text).join('');
        const matchList = this.getMatchList(content, this.keyword);
        this.matchCount = matchList.length;
        this.lightIndex = this.matchCount ? 1 : 0;
        this.replaceMatchResult(textNodes, textList, matchList);
        this.contentShow = div.innerHTML;
    }

    private scrollTo(index: number) {
        this.$nextTick(() => {
            const node = this.$el.querySelector(`font[${this.flag}='${index}']`);
            if (node) {
                this.lightIndex = index;
                node.scrollIntoView();
            }
        });
    }

    public searchNext(): void {
        this.$nextTick(() => {
            const idx = this.lightIndex >= this.matchCount ? 1 : this.lightIndex + 1;
            this.scrollTo(idx);
        });
    }

    public searchLast(): void {
        this.$nextTick(() => {
            const idx = this.lightIndex <= 1 ? this.matchCount : this.lightIndex - 1;
            this.scrollTo(idx);
        });
    }

    private setStyle() {
        let style = document.head.querySelector(this.styleSelector) as HTMLElement;
        if (!style) {
            style = document.createElement('style');
            style.setAttribute(this.flag, '1');
        }
        style.innerText = `font[${this.flag}]{${this.highlightStyle}}font[${this.flag}='${this.lightIndex}']{${this.currentStyle}}`;
        document.head.appendChild(style);
    }

    private clearStyle() {
        const style = document.head.querySelector(this.styleSelector);
        style && document.head.removeChild(style);
    }
}
