
import { Options, Vue } from 'vue-class-component';
import SearchQuestion from '@/components/searchQuestion.vue';

@Options({
    components: {
        SearchQuestion,
    },
})
export default class Question extends Vue {}
